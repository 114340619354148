<template>
    <div v-if="is_mounted">
        <div align="center">
            <h4 class="q-mb-sm">Rinnovo pratiche in scadenza</h4>
            Ufficio: {{ unita_operativa }}
            <br><br>
            <hr>
        </div>

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <div class="q-mr-sm q-ml-sm">
            <br>
            <table class="table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th>Fornitore/Operatore</th>
                        <th>Contraente</th>
                        <th>Compagnia/Tariffa</th>
                        <th>Frazionamento</th>
                        <th>Tacito Rinnovo</th>
                        <th>Disdettata</th>
                        <th>Premio in scadenza</th>
                        <th>Premio rinnovo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(contratto,index) in elenco_contratti_da_lavorare" v-bind:key="index">
                        <td align="center">

                            <q-btn
                                style="width: 200px;"
                                color="deep-orange"
                                glossy
                                label="RINNOVA"
                                @click.native="onRinnovaRCProfessionale(contratto)"
                                v-if="(contratto.tacito_rinnovo) && (!contratto.disdettato) && (contratto.prodotto_id === 'rcp_tecnico' || contratto.prodotto_id === 'rcp_medico' || contratto.prodotto_id ==='rcp_sanitario')"/>

                            <q-btn
                                style="width: 200px;"
                                color="deep-orange"
                                glossy
                                label="RINNOVA"
                                @click.native="onRinnovaAltriProdotti(contratto)"
                                v-if="(contratto.tacito_rinnovo) && (!contratto.disdettato) && (contratto.prodotto_id !== 'rcp_tecnico' && contratto.prodotto_id !== 'rcp_medico' && contratto.prodotto_id !=='rcp_sanitario')"/>

                            <q-btn
                                style="width: 200px;"
                                color="deep-orange"
                                glossy
                                label="CONVALIDA"
                                @click.native="onConvalidaDisdetta(contratto)"
                                v-if="contratto.disdettato"/>

                            <q-btn
                                style="width: 200px;"
                                color="positive"
                                glossy
                                label="NUOVO PREVENTIVO"
                                @click.native="onNuovoPreventivo(contratto)"
                                v-if="!contratto.tacito_rinnovo"/>
                        </td>
                        <td>{{ contratto.fornitore }}<br>{{ contratto.operatore }}</td>
                        <td>{{ nome_contraente(contratto.contraente) }}</td>
                        <td>{{ contratto.compagnia }}<br>{{ contratto.prodotto_id }}</td>
                        <td>{{ getFrazionamento(contratto) }}</td>
                        <td align="center">
                            <q-checkbox size="lg" v-model="contratto.tacito_rinnovo" val="lg" disable />
                        </td>
                        <td align="center">{{ contratto_disdettato(contratto.disdettato) }}</td>
                        <td align="right">{{ contratto.premioinscadenza }} Euro</td>
                        <td align="right">{{ contratto.premio_rinnovo }} Euro</td>
                    </tr>
                    <tr v-if="elenco_contratti_da_lavorare.length === 0">
                        <td colspan="9" align="center">NESSUNA PRATICA DA RINNOVARE &Egrave; STATA IDENTIFICATA</td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import policy from "@/libs/policy";
    import { mapFields} from 'vuex-map-fields';
    import { mapActions } from 'vuex';
    import store from "@/store";
    //import { exportFile } from 'quasar';
    //import commonLib from "@/libs/commonLib";
    //import QQButton from "@/components/QQButton.vue";


    export default {
        name: "RinnovaPraticheDaUfficio",
        data() {
            return {
                is_loading: true,
                elenco_contratti_da_lavorare: [],
                is_mounted: false
            }
        },
        components: {
            //QQButton
        },
        computed: {
            ...mapGetters([
                "jwtPayload"
            ]),
            ...mapFields('formPreventivo', [
                "vuole_analisi_necessita",
                "step"
            ]),
            ...mapFields('', [
                "sezione_visualizzata",
            ]),
            operatore() { return this.jwtPayload.operatore},
            unita_operativa() { return this.jwtPayload.nome_unita_operativa},
            id_unita_operativa() {
                return policy.IdUnitaOperativa();
            },
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            ...mapActions({
                fetchContrattiInScadenzaUfficio: "gestioneRinnovi/fetchContrattiInScadenzaUfficio",
                fetchRinnovaContrattoRcp: "gestioneRinnovi/fetchRinnovaContrattoRcp",
                fetchRinnovaContrattoNoTacitoRinnovo: "gestioneRinnovi/fetchRinnovaContrattoNoTacitoRinnovo",
                fetchRinnovaContrattoAltriRami: "gestioneRinnovi/fetchRinnovaContrattoAltriRami",
                fetchConfermaDisdettaRinnovoContratti: "gestioneRinnovi/fetchConfermaDisdettaRinnovoContratti"
            }),
            contratto_disdettato(value) {
                if (value) return "SI";

                return "NO";
            },
            nome_contraente(value) {
                return value.toUpperCase();
            },
            getFrazionamento(contratto) {
                var frazionamento = contratto.frazionamento;

                if (frazionamento === "") frazionamento = "ANNUALE";

                return frazionamento.toUpperCase();
            },
            async onRinnovaRCProfessionale(contratto) {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, crea preventivo di rinnovo"
                    },
                    title: 'Attenzione',
                    persistent: true,
                    message: 'Il rinnovo di un contratto RC Professionale, comporta la generazione di un nuovo preventivo in quotazione. Le procedure di acquisto sono pertanto uguali a quelle di un preventivo standard su cui bisogna aggiornare tutti i questionari. Procedo con il rinnovo?',
                }).onOk(async () => {
                    // Trasforma il tacito_rinnovo da boolean a integer
                    if (contratto.tacito_rinnovo)  contratto.tacito_rinnovo = 1;
                                              else contratto.tacito_rinnovo = 0;

                    await this.fetchRinnovaContrattoRcp(contratto);
                    this.elenco_contratti_da_lavorare = await this.fetchContrattiInScadenzaUfficio({
                        idUnitaOperativa: this.id_unita_operativa,
                        isSede: this.isSede
                    });

                    this.aggiornaTacitoRinnovo();
                });
            },
            async onRinnovaAltriProdotti(contratto) {
                var msg = "";
                if (this.getFrazionamento(contratto) === "SEMESTRALE") {
                    msg = "Il rinnovo comporta la generazione di una quietanza su un contratto attivo. Procedo con la generazione?";
                } else {
                    msg = "Il rinnovo di questo contratto, comporta la generazione di un nuovo preventivo con l`importo gia valorizzato. Le procedure di acquisto sono pertanto uguali a quelle di un preventivo standard. Procedo con il rinnovo?";
                }

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, genera"
                    },
                    title: 'Attenzione',
                    persistent: true,
                    message: msg,
                }).onOk(async () => {
                    // Trasforma il tacito_rinnovo da boolean a integer
                    if (contratto.tacito_rinnovo)  contratto.tacito_rinnovo = 1;
                                              else contratto.tacito_rinnovo = 0;
                    await this.fetchRinnovaContrattoAltriRami(contratto);
                    this.elenco_contratti_da_lavorare =
                        await this.fetchContrattiInScadenzaUfficio({
                            idUnitaOperativa: this.id_unita_operativa,
                            isSede: this.isSede
                        });

                    this.aggiornaTacitoRinnovo();
                });

            },
            async onConvalidaDisdetta(contratto) {
                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, prendo atto della disdetta"
                    },
                    title: 'Attenzione',
                    persistent: true,
                    message: 'Il contratto e` stato disdettato pertanto nessuna operazione di rinnovo e` attivabile. Procedo con la convalida?',
                }).onOk(async () => {
                    // Trasforma il tacito_rinnovo da boolean a integer
                    if (contratto.tacito_rinnovo)  contratto.tacito_rinnovo = 1;
                                              else contratto.tacito_rinnovo = 0;

                    // Registra la conferma sul DB
                    await this.fetchConfermaDisdettaRinnovoContratti(contratto);

                    // Aggiorna la pagina
                    this.elenco_contratti_da_lavorare =
                        await this.fetchContrattiInScadenzaUfficio({
                            idUnitaOperativa: this.id_unita_operativa,
                            isSede: this.isSede
                        });

                    this.aggiornaTacitoRinnovo();
                });
            },
            onNuovoPreventivo(contratto) {

                this.$q.dialog({
                    cancel: {
                        focus: true,
                        flat: false,
                        label: "No",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, crea nuovo preventivo"
                    },
                    title: 'Attenzione',
                    persistent: true,
                    message: 'Il rinnovo di un contratto senza Tacito Rinnovo, comporta la richiesta di un nuovo preventivo standard. Procedo con il nuovo preventivo?',
                }).onOk(async () => {
                    console.log(contratto);
                    // Aggiusta il valore del tacito rinnovo altrimenti il backend si incazza
                    if (contratto.tacito_rinnovo) contratto.tacito_rinnovo = 1;
                                             else contratto.tacito_rinnovo = 0;

                    // Aggiorna il record dei contratti rinnovati
                    await this.fetchRinnovaContrattoNoTacitoRinnovo(contratto);
                    // Salta direttamente a nuovo preventivo
                    let area = "";
                    store.commit("formPreventivo/clear");
                    store.commit("setArea",area);
                    this.vuole_analisi_necessita = true;
                    this.sezione_visualizzata = "Preventivi";
                    this.step = 2;
                    this.$router.push({"path" :"/preventivi/FormPreventivo?area="+area});
                });



            },
            aggiornaTacitoRinnovo() {
                // Trasforma il tacito_rinnovo da integer a boolean
                for (var i=0;i<this.elenco_contratti_da_lavorare.length;i++) {
                    var contratto = this.elenco_contratti_da_lavorare[i];
                    if (contratto.tacito_rinnovo === 1)  contratto.tacito_rinnovo = true;
                                                    else contratto.tacito_rinnovo = false;
                }
            }
        },
        async mounted() {
            this.is_mounted = false;

            this.elenco_contratti_da_lavorare = await this.fetchContrattiInScadenzaUfficio({
                idUnitaOperativa: this.id_unita_operativa,
                isSede: this.isSede
            });

            // Trasforma il tacito_rinnovo da integer a boolean
            //for (var i=0;i<this.elenco_contratti_da_lavorare.length;i++) {
            //    var contratto = this.elenco_contratti_da_lavorare[i];
            //    if (contratto.tacito_rinnovo === 1)  contratto.tacito_rinnovo = true;
            //                                    else contratto.tacito_rinnovo = false;
            //}
            this.aggiornaTacitoRinnovo();

            console.log("elenco:",this.elenco_contratti_da_lavorare);

            this.is_mounted = true;
        }
    }
</script>

<style scoped>

    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>
