/* ===============================================================
    Libreria per la gestione delle policy associate all'Utente
    identificato tramite JWT. Recupera le informazioni e le
    mette a disposizione tramite funzioni specializzate. Ogni
    funzione recupera il JWT in autonomia e non necessitano
    pertanto di parametri.
   =============================================================== */

import store from '@/store';
import commonLib from "@/libs/commonLib.js";

let policy = {
    IdUnitaOperativa: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.id_unita_operativa;
    },
    IdPersonaOperativa: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.id_persona_operativa;
    },
    IdIntermediarioCapogruppo: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.id_intermediario_capogruppo;
    },
    isSede() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        // return jwt.ruolo === "SEDE";
        return jwt.is_sede;
    },
    UnitaOperativeSottostanti: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        var elenco = jwt.unita_operative_sottostanti;
        if (elenco.length === 0) {
            elenco.push(this.IdUnitaOperativa());
        }

        return elenco;
    },
    PuoModificarePreventiviDiTutti: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_modifica_tutti;
    },
    PuoModificarePreventiviPropri: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_modifica_propri;
    },
    PuoModificarePreventiviGruppo: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_modifica_gruppo;
    },
    PuoModificarePraticheDiTutti: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.pratiche_modifica_tutti;
    },
    PuoModificarePraticheProprie: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.pratiche_modifica_propri;
    },
    PuoModificarePraticheGruppo: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.pratiche_modifica_gruppo;
    },
    PuoCalcolareNuoviPreventivi: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_calcola;
    },
    AbilitaLavorazionePreventivi: function () {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_abilita_lavorazione;
    },
    isProfessionistiEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.professionisti_abilita;
    },
    isPersoneEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.persone_abilita;
    },
    isAziendeEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.aziende_abilita;
    },
    isCauzioniEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.cauzioni_abilita;
    },
    isAutoEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.auto_abilita;
    },
    isAltriRamiEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.altri_rami_abilita;
    },
    isCorpiNatantiEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.retail_abilita;
    },
    isRetailEnabled() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.corpi_natanti_abilita;
    },
    PuoCancellarePreventiviPropri() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_cancella_propri;
    },
    PuoCancellarePreventiviGruppo() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.preventivi_cancella_gruppo;
    },
    PuoCancellareTuttiIPreventivi() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        //console.log("jwt:",jwt);
        return jwt.preventivi_cancella_tutti;
    },
    PuoCancellarePraticheProprie() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.pratiche_cancella_propri;
    },
    PuoCancellarePraticheGruppo() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        return jwt.pratiche_cancella_gruppo;
    },
    PuoCancellareTutteLePratiche() {
        var jwt = commonLib.JWTtoJSon(store.state.jwt);
        //console.log("jwt:",jwt);
        return jwt.pratiche_cancella_tutti;
    }
}

export default policy;
